import cm1 from "../pages/images/cm1.png";
import cm2 from "../pages/images/cm2.png";
import cm3 from "../pages/images/cm3.png";
import cm4 from "../pages/images/cm4.png";
import cm5 from "../pages/images/cm5.png";
import cm6 from "../pages/images/cm6.png";
import cm7 from "../pages/images/cm7.png";
import ccm1 from "../pages/images/ccm1.png";
import ccm2 from "../pages/images/ccm2.png";
import ccm3 from "../pages/images/ccm3.png";
import ccm4 from "../pages/images/ccm4.png";
import ccm5 from "../pages/images/ccm5.png";
import ccm6 from "../pages/images/ccm6.png";
import ccm7 from "../pages/images/ccm7.png";
import ccm8 from "../pages/images/ccm8.png";
import ccm9 from "../pages/images/ccm9.png";
import ccm10 from "../pages/images/ccm10.png";
import group1 from "../pages/images/group1.png";
import group2 from "../pages/images/group2.png";
import group3 from "../pages/images/group3.png";
import group4 from "../pages/images/group4.png";
import group5 from "../pages/images/group5.png";
import group6 from "../pages/images/group6.png";
import group7 from "../pages/images/group7.png";
import group8 from "../pages/images/group8.png";
import rectangle1 from "../pages/images/Rectangle1.png";
import rectangle2 from "../pages/images/Rectangle2.png";
import rectangle3 from "../pages/images/Rectangle3.png";
import rectangle4 from "../pages/images/Rectangle4.png";
import rectangle5 from "../pages/images/Rectangle5.png";
import rectangle6 from "../pages/images/Rectangle6.png";

export const cmdata = [
  {
    photo: cm1,
    heading: "Sri. Sri Hari Kotela",
    para: "President",
  },
  {
    photo: cm2,
    heading: "Sri. Venkateshwara Rao Sunkara",
    para: "Vice-President-1",
  },
  {
    photo: cm3,
    heading: "Sri. Janardhan Jawahar Bommadevara",
    para: "Vice-President-2",
  },
  {
    photo: cm4,
    heading: "Sri. Vinayaka Swamy Patsa",
    para: "General Secretary",
  },
];

export const cmdata2 = [
  {
    photo: cm5,
    heading: "Sri.Mareswara Rao Guruju",
    para: "Joint Secretary-1",
  },
  {
    photo: cm6,
    heading: "Sri. Shanker Babu Katragadda",
    para: "Joint Secretary-2",
  },
  {
    photo: cm7,
    heading: "Sri Ravinder Chaluvadi",
    para: "Treasurer",
  },
];

export const ccmdata = [
  {
    photo: ccm1,
    heading: "Sri Venkata Ratnam Anugolu  ",
  },
  {
    photo: ccm2,
    heading: "Sri Narasimha Rao Bandaru",
  },
  {
    photo: ccm3,
    heading: "Sri Parameswara Rao Parasa",
  },
  {
    photo: ccm4,
    heading: "Sri Narendra Babu N",
  },
  {
    photo: ccm5,
    heading: "Sri Venkata Krishna Rao Thota",
  },
  {
    photo: ccm6,
    heading: "Sri Trivikram Vidyasagar Jampa",
  },
  {
    photo: ccm7,
    heading: "Sri Hari Krishna Pothula",
  },
  {
    photo: ccm8,
    heading: "Sri Sammeta Prasad",
  },
];

export const ccmdata2 = [
  {
    photo: ccm9,
    heading: "Sri K. Gopala  Krishna",
  },
  {
    photo: ccm10,
    heading: "Sri Katari  Apparao",
  },
];

export const gallery = [
  rectangle1,
  rectangle2,
  rectangle3,
  rectangle4,
  rectangle5,
  rectangle6,
];

export const marqueedata = [
  {
    name: "Gopala krishna m",
    tag: "IAS",
    info: "AITBK is Doing Extremely very well. working very well for the community Empowerment",
  },
  {
    name: "Narendra Babu N",
    tag: "IAS",
    info: "AITBK is Doing Extremely very well. working very well for the community Empowerment",
  },
  {
    name: "Ravinder Chaluvadi",
    tag: "IAS",
    info: "AITBK is Doing Extremely very well. working very well for the community Empowerment",
  },
  {
    name: "Harikrishna Pothula",
    tag: "IAS",
    info: "AITBK is Doing Extremely very well. working very well for the community Empowerment",
  },
  {
    name: "S.Ramana Rao",
    tag: "IAS",
    info: "AITBK is Doing Extremely very well. working very well for the community Empowerment",
  },
];

export const help = [
  {
    image: group1,
    heading: "Cultural Events",
  },
  {
    image: group2,
    heading: "Scholarships",
  },
  {
    image: group3,
    heading: "Awards",
  },
  {
    image: group4,
    heading: "Parinaya Vedika",
  },
];

export const count = [
  {
    image: group5,
    heading: "4850+",
    para: "Total Events",
  },
  {
    image: group6,
    heading: "3686+",
    para: "Raised Funds",
  },
  {
    image: group7,
    heading: "480+",
    para: "Scholarships",
  },
  {
    image: group8,
    heading: "2068+",
    para: "Happy Members",
  },
];

export const gender = [{ label: "Male" }, { label: "Female" }];

export const education = [
  { label: "B.Tech" },
  { label: "B.Sc" },
  { label: "BBA" },
  { label: "BComm" },
  { label: "BEd" },
  { label: "BE" },
  { label: "LLB" },
  { label: "B Pharma" },
  { label: "M.B.B.S" },
];

export const profession = [
  { label: "Engineer" },
  { label: "Business" },
  { label: "Doctor" },
  { label: "Lawyer" },
  { label: "Teacher" },
  { label: "Chartered Accountant" },
  { label: "Pilot" },
  { label: "Others" },
];

export const district = [
  { label: "Adilabad" },
  { label: "Alluri Sitharama Raju" },
  { label: "Anakapalli" },
  { label: "Ananthapuram" },
  { label: "Annamayya" },
  { label: "Bapatla" },
  { label: "Bhadadri Kothagudem" },
  { label: "Chittoor" },
  { label: "Dr. B.R. Ambedkar Konaseema" },
  { label: "East Godavari" },
  { label: "Eluru" },
  { label: "Guntur" },
  { label: "Hanumakonda" },
  { label: "Hyderabad" },
  { label: "Jagtial" },
  { label: "Jangaon" },
  { label: "Jayashankar Bhoopalpally" },
  { label: "Jogulamba Gadwal" },
  { label: "Kakinada" },
  { label: "Kamareddy" },
  { label: "Karimnagar" },
  { label: "Khammam" },
  { label: "Kona Seema" },
  { label: "Komaram Bheem Asifabad" },
  { label: "Krishna" },
  { label: "Kurnool" },
  { label: "Mahabubabad" },
  { label: "Mahabubnagar" },
  { label: "Mancherial" },
  { label: "Manyam" },
  { label: "Medak" },
  { label: "Medchal-Malkajgiri" },
  { label: "Mulug" },
  { label: "Nagarkurnool" },
  { label: "Nalgonda" },
  { label: "Nandyal" },
  { label: "Narayanpet" },
  { label: "Nirmal" },
  { label: "Nizamabad" },
  { label: "NTR District" },
  { label: "Palnadu" },
  { label: "Peddapalli" },
  { label: "Prakasam" },
  { label: "Rajanna Sircilla" },
  { label: "Rangareddy" },
  { label: "Sangareddy" },
  { label: "Siddipet" },
  { label: "SPS Nellore" },
  { label: "Sri Balaji District" },
  { label: "Sri Satyasai District" },
  { label: "Srikakulam" },
  { label: "Suryapet" },
  { label: "Vikarabad" },
  { label: "Vishakhapatnam" },
  { label: "Vizianagaram" },
  { label: "Wanaparthy" },
  { label: "Warangal" },
  { label: "West Godavari" },
  { label: "Yadadri Bhuvanagiri" },
  { label: "YSR Kadapa" },
];

export const states = [
  { label: "Andhra Pradesh" },
  // { label: "Arunachal Pradesh" },
  // { label: "Assam" },
  // { label: "Bihar" },
  // { label: "Chhattisgarh" },
  // { label: "Delhi [National Capital Territory (NCT)]" },
  // { label: "Goa" },
  // { label: "Gujarat" },
  // { label: "Haryana" },
  // { label: "Himachal Pradesh" },
  // { label: "Jharkhand" },
  // { label: "Karnataka" },
  // { label: "Kerala" },
  // { label: "Maharashtra" },
  // { label: "Madhya Pradesh" },
  // { label: "Manipur" },
  // { label: "Meghalaya" },
  // { label: "Mizoram" },
  // { label: "Nagaland" },
  // { label: "Odisha" },
  // { label: "Punjab" },
  // { label: "Rajasthan" },
  // { label: "Sikkim" },
  // { label: "Tamil Nadu" },
  // { label: "Tripura" },
  { label: "Telangana" },
  // { label: "Uttar Pradesh" },
  // { label: "West Bengal" },
  // { label: "Andaman & Nicobar (UT)" },
  // { label: "Chandigarh (UT)" },
  // { label: "Dadra & Nagar Haveli and Daman & Diu (UT)" },
  // { label: "Jammu & Kashmir (UT)" },
  // { label: "Ladakh (UT)" },
  // { label: "Lakshadweep (UT)" },
  // { label: "Puducherry (UT)" },
];

export const country = [{ label: "India" }, { label: "NRI" }];

export const childGender = [
  { label: "Male" },
  { label: "Female" },
  { label: "Others" },
];

export const initialState = {
  firstName: "",
  middleName: "",
  lastName: "",
  dob: "",
  mobileNumber: "",
  email: "",
  gender: "",
  profession: "",
  education: "",
  address: "",
  address2: "",
  pincode: "",
  state: "",
  country: "",
  fatherName: "",
  motherName: "",
  spouseName: "",
  spouseAge: "",
  spouseGender: "",
  spouseProfession: "",
  spouseEducation: "",
  nativeAddress: "",
  noOfChildren: "",
  nameOfChild: "",
  ageOfChild: "",
  childGender: "",
  aadharNumber: "",
  voterId: "",
  introduction: "",
  description: "",
  presentOccupation: "",
  referenceOne: "",
  referenceTwo: "",
  membershipType: "",
};

export const addressType = [
  { label: "Home" },
  { label: "Office" },
  { label: "Others" },
];

export const category = [
  { label: "Telega " },
  { label: "Balija" },
  { label: "Kapu" },
  { label: "Vontari" },
];

export const registrationData = {
  userId: "user0013",
  profilePic: "https://example.com/profilepic.jpg",
  fullName: "John Doe",
  dateOfBirth: "1985-05-15T06:58:07.967Z",
  gender: "Male",
  category: "Professional",
  address: [
    {
      addressId: 1,
      addressLine1: "123 Elm Street",
      addressLine2: "Apt 4B",
      country: "USA",
      state: "CA",
      city: "Los Angeles",
      postalCode: "90001",
      creationDate: "2024-08-06T06:58:07.967Z",
      lastModifiedDate: "2024-08-06T06:58:07.967Z",
      defaultAddress: true,
      addressType: "Home",
    },
  ],
  emailAddress: "rmonshu00@gmail.com",
  phoneNumber: "9898989008",
  education: "Bachelor's Degree in Computer Science",
  profession: "Software Engineer",
  familyDetails: {
    fatherName: "Richard Doe",
    motherName: "Emily Doe",
    spouseName: "Jane Doe",
    spouseOccupation: "Graphic Designer",
    childern: [
      {
        name: "Alice Doe",
        education: "High School",
        profession: "Student",
        isMarried: "No",
        childAge: "16",
      },
    ],
    married: true,
  },
  aadharCard: "1234-5678-9101",
  voterIdCard: "VOTER-123456",
  occupation: "Software Engineer",
  brieflyTellAboutYourself:
    "I am a dedicated software engineer with over 10 years of experience in the tech industry. I am passionate about coding and problem-solving.",
  reasonToJoinAITBKS:
    "I am interested in joining AITBKS to connect with like-minded professionals and contribute to the community's growth.",
  reference1: "Dr. Alan Smith",
  reference2: "Ms. Laura Brown",
  categoryOfMembership: "Gold",
  requestForMembershipApplicationFromDeclaration: true,
  password: "securepassword123",
  confrimPassowrd: "securepassword123",
  createdDate: "2024-08-06T06:58:07.967Z",
  lastModifiedDate: "2024-08-06T06:58:07.967Z",
  nativePlace: "San Francisco, CA",
  status: "Pending",
  paymentInfo: {
    trasactionId: "TXN123456789",
    amountPaid: "100.00",
    transactionDate: "2024-08-06",
    paymentDetailDocument: "https://example.com/paymentdetails.pdf",
  },
  applicantChoosenMembership: "Gold",
  committeeChoosenMembershipForApplicant: "",
  presidentChoosenMembershipForApplicant: "",
  presidentRemarksForApplicant: "",
  committeeRemarksForApplicant: "",
  applicationForMembershipDeclaration: true,
  memberOfOtherCommunity: true,
};

export const memberCategory = [
  { label: "President" },
  { label: "Committee" },
  { label: "Accountant" },
];

export const toEmail = [
  { label: "trustee" },
  { label: "patron" },
  { label: "lifemember" },
  { label: "all" },
];
